<template>
  <BaseDialog :dialog="dialog" :title="title" :subTitle="doc.name" @close="close">
    <iframe v-if="!loading" class="doc-container" :src="doc.blob" />
    <div v-else class="d-flex align-center justify-center doc-container">
      <v-progress-circular :size="50" :width="7" color="primary" indeterminate />
    </div>
  </BaseDialog>
</template>

<script>
import { mapGetters } from 'vuex';
import BaseDialog from '@/components/base/BaseDialog';

export default {
  name: 'DocumentViewer',
  components: {
    BaseDialog,
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    item: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  watch: {
    item(val) {
      this.loading = true;
      this.$store.dispatch('File/getData', { data: val, type: 'doc' }).finally(() => (this.loading = false));
    },
  },
  computed: {
    ...mapGetters({
      doc: 'File/doc',
    }),
  },
  methods: {
    close() {
      this.$emit('close');
      this.$emit('update:item', {});
      this.$store.commit('File/RESET_DOC');
    },
  },
};
</script>

<style lang="scss" scoped>
.doc-container {
  width: 100%;
  height: 65vh;
}
</style>
