var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('section',{staticClass:"d-block d-sm-none"},[_c('div',{staticClass:"mb-3"},[_c('v-text-field',{staticClass:"rounded-lg",attrs:{"outlined":"","dense":"","placeholder":((_vm.$t('content.search')) + "..."),"single-line":"","hide-details":true,"clearable":"","background-color":"#fff"},model:{value:(_vm.filter.deliverableName),callback:function ($$v) {_vm.$set(_vm.filter, "deliverableName", $$v)},expression:"filter.deliverableName"}})],1),_c('div',{staticClass:"mb-3"},[_c('v-text-field',{staticClass:"rounded-lg",attrs:{"outlined":"","dense":"","placeholder":((_vm.$t('content.search_idfl')) + "..."),"single-line":"","hide-details":true,"clearable":"","background-color":"#fff"},model:{value:(_vm.filter.idflNumber),callback:function ($$v) {_vm.$set(_vm.filter, "idflNumber", $$v)},expression:"filter.idflNumber"}})],1),_c('div',{staticClass:"mb-3"},[_c('v-select',{staticClass:"rounded-lg",attrs:{"background-color":"#fff","outlined":"","dense":"","single-line":"","multiple":"","hide-details":"","items":_vm.types,"item-text":"name","item-value":"id","placeholder":_vm.$t('content.select_type')},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('v-chip',{staticClass:"chip-selector ma-2",class:{ 'chip-selector-single': _vm.filter.type.length === 1 },attrs:{"small":"","color":"primary"}},[_vm._v(" "+_vm._s(item.name)+" ")]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption chip-extra"},[_vm._v(" +"+_vm._s(_vm.filter.type.length - 1)+" ")]):_vm._e()]}}]),model:{value:(_vm.filter.type),callback:function ($$v) {_vm.$set(_vm.filter, "type", $$v)},expression:"filter.type"}})],1),_c('div',{staticClass:"mb-3"},[_c('date-picker',{staticStyle:{"width":"100%"},attrs:{"format":"YYYY-MM-DD","range":"","value-type":"format","disabled-date":_vm.utilityMixin_disabledDateMaxNow,"clearable":false},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{staticClass:"rounded-lg",attrs:{"outlined":"","dense":"","single-line":"","placeholder":_vm.$t('content.select_date'),"readonly":"","hide-details":true,"background-color":"#fff","clearable":""},model:{value:(_vm.filter.sentAt),callback:function ($$v) {_vm.$set(_vm.filter, "sentAt", $$v)},expression:"filter.sentAt"}},[_c('svg-icon',{staticClass:"mt-1",attrs:{"slot":"append","icon":"icon-calendar"},slot:"append"})],1)]},proxy:true}]),model:{value:(_vm.filter.sentAt),callback:function ($$v) {_vm.$set(_vm.filter, "sentAt", $$v)},expression:"filter.sentAt"}},[_c('i',{attrs:{"slot":"icon-calendar"},slot:"icon-calendar"})])],1)]),_c('v-data-table',{staticClass:"custom-table",attrs:{"dense":"","hide-default-footer":"","loading":_vm.loading,"headers":_vm.table.headers,"items":_vm.deliverables.results,"server-items-length":_vm.deliverables.overall_total},scopedSlots:_vm._u([{key:"header.file_name",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('div',{staticClass:"mt-2"},[_c('v-text-field',{staticClass:"rounded-lg",attrs:{"outlined":"","dense":"","placeholder":((_vm.$t('content.search')) + "..."),"single-line":"","hide-details":true,"clearable":"","background-color":"#fff"},model:{value:(_vm.filter.deliverableName),callback:function ($$v) {_vm.$set(_vm.filter, "deliverableName", $$v)},expression:"filter.deliverableName"}})],1)]}},{key:"header.idfl_numbers",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('div',{staticClass:"mt-2"},[_c('v-text-field',{staticClass:"rounded-lg",attrs:{"outlined":"","dense":"","placeholder":((_vm.$t('content.search_idfl')) + "..."),"single-line":"","hide-details":true,"clearable":"","background-color":"#fff"},model:{value:(_vm.filter.idflNumber),callback:function ($$v) {_vm.$set(_vm.filter, "idflNumber", $$v)},expression:"filter.idflNumber"}})],1)]}},{key:"header.type",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('div',{staticClass:"mt-2",staticStyle:{"max-width":"250px"}},[_c('v-select',{staticClass:"rounded-lg",attrs:{"background-color":"#fff","outlined":"","dense":"","single-line":"","multiple":"","hide-details":"","items":_vm.types,"item-text":"name","item-value":"id","placeholder":_vm.$t('content.select_type')},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('v-chip',{staticClass:"chip-selector ma-2",class:{ 'chip-selector-single': _vm.filter.type.length === 1 },attrs:{"small":"","color":"primary"}},[_vm._v(" "+_vm._s(item.name)+" ")]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption chip-extra"},[_vm._v(" +"+_vm._s(_vm.filter.type.length - 1)+" ")]):_vm._e()]}}],null,true),model:{value:(_vm.filter.type),callback:function ($$v) {_vm.$set(_vm.filter, "type", $$v)},expression:"filter.type"}})],1)]}},{key:"header.sent_at",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('div',{staticClass:"mt-2"},[_c('date-picker',{attrs:{"format":"YYYY-MM-DD","range":"","value-type":"format","disabled-date":_vm.utilityMixin_disabledDateMaxNow,"clearable":false},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{staticClass:"rounded-lg",attrs:{"outlined":"","dense":"","single-line":"","placeholder":_vm.$t('content.select_date'),"readonly":"","hide-details":true,"background-color":"#fff","clearable":""},model:{value:(_vm.filter.sentAt),callback:function ($$v) {_vm.$set(_vm.filter, "sentAt", $$v)},expression:"filter.sentAt"}},[_c('svg-icon',{staticClass:"mt-1",attrs:{"slot":"append","icon":"icon-calendar"},slot:"append"})],1)]},proxy:true}],null,true),model:{value:(_vm.filter.sentAt),callback:function ($$v) {_vm.$set(_vm.filter, "sentAt", $$v)},expression:"filter.sentAt"}},[_c('i',{attrs:{"slot":"icon-calendar"},slot:"icon-calendar"})])],1)]}},{key:"item.file_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.changeName(item.file_name))+" ")]}},{key:"item.idfl_numbers",fn:function(ref){
var value = ref.value;
return [(value.length > 1)?_c('v-expansion-panels',{staticClass:"expansion-title",attrs:{"accordion":"","flat":""}},[_c('v-expansion-panel',{staticClass:"expansion-panel"},[_c('v-expansion-panel-header',{staticClass:"px-0",scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-icon',{attrs:{"color":"blue lighten-1"}},[_vm._v(" mdi-menu-down ")])]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(value[0])+" ")]),_vm._l((value.slice(1)),function(item,index){return _c('v-expansion-panel-content',{key:index},[_vm._v(" "+_vm._s(item)+" ")])})],2)],1):_c('v-expansion-panels',{attrs:{"accordion":"","flat":""}},[_c('v-expansion-panel',{staticClass:"expansion-panel"},[_c('v-expansion-panel-header',{staticClass:"px-0",attrs:{"hide-actions":""}},[_vm._v(" "+_vm._s(value[0])+" ")])],1)],1)]}},{key:"item.sent_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.utilityMixin_formatDateTime(item.sent_at))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('BaseActionButton',{attrs:{"tooltipsText":_vm.$t('content.view_document')},on:{"click":function($event){return _vm.onViewDocument(item)}}},[_c('SvgIcon',{attrs:{"icon":"icon-eye","width":"20px","height":"20px"}})],1),_c('BaseActionButton',{attrs:{"disabled":_vm.loadingShare && _vm.selectedItem == item,"tooltipsText":_vm.$t('content.share'),"selectedItem":item},on:{"click":function($event){return _vm.onViewUserShared(item)}}},[(_vm.loadingShare && _vm.selectedItem == item)?_c('v-progress-circular',{attrs:{"size":22,"color":"primary-light","indeterminate":""}}):_c('SvgIcon',{attrs:{"icon":"icon-share","width":"20px","height":"20px"}})],1),_c('BaseActionButton',{attrs:{"tooltipsText":_vm.$t('content.download')},on:{"click":function($event){return _vm.onDownload(item)}}},[_c('SvgIcon',{attrs:{"icon":"icon-download","width":"20px","height":"20px"}})],1)]}},{key:"no-data",fn:function(){return [_c('BaseNoData',{attrs:{"message":_vm.$t('content.deliverable_no_data')}})]},proxy:true},{key:"no-results",fn:function(){return [_c('BaseDataNotFound',{attrs:{"message":_vm.$t('content.deliverable_notfound_message')}})]},proxy:true},{key:"footer",fn:function(){return [_c('BaseTableFooter',{attrs:{"data":_vm.deliverables,"filter":_vm.filter,"params":_vm.table.params,"customLength":"","customLengthFunc":_vm.onCustomLengthTable,"contentText":"content.perType"},on:{"change":_vm.onChangeTable}})]},proxy:true}],null,true)}),_c('DeliverableDialogShare',{attrs:{"deliverable":_vm.selectedItem,"dialog":_vm.dialogUserShared},on:{"close":_vm.onCloseDialog}}),_c('DocumentViewer',{attrs:{"dialog":_vm.dialogDocument,"item":_vm.selectedItem,"title":_vm.selectedItem.file_name ? _vm.changeName(_vm.selectedItem.file_name) : ''},on:{"update:item":function($event){_vm.selectedItem=$event},"close":_vm.onCloseDialog}}),_c('BaseAlert',{ref:"alert"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }