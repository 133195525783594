<template>
  <div>
    <!-- Deliverable - Filter (for mobile view only) -->
    <section class="d-block d-sm-none">
      <div class="mb-3">
        <v-text-field
          outlined
          dense
          v-model="filter.deliverableName"
          :placeholder="`${$t('content.search')}...`"
          single-line
          class="rounded-lg"
          :hide-details="true"
          clearable
          background-color="#fff"
        />
      </div>
      <div class="mb-3">
        <v-text-field
          outlined
          dense
          v-model="filter.idflNumber"
          :placeholder="`${$t('content.search_idfl')}...`"
          single-line
          class="rounded-lg"
          :hide-details="true"
          clearable
          background-color="#fff"
        />
      </div>
      <div class="mb-3">
        <v-select
          class="rounded-lg"
          background-color="#fff"
          outlined
          dense
          v-model="filter.type"
          single-line
          multiple
          hide-details
          :items="types"
          item-text="name"
          item-value="id"
          :placeholder="$t('content.select_type')"
        >
          <template #selection="{ item, index }">
            <v-chip
              v-if="index === 0"
              class="chip-selector ma-2"
              :class="{ 'chip-selector-single': filter.type.length === 1 }"
              small
              color="primary"
            >
              {{ item.name }}
            </v-chip>
            <span v-if="index === 1" class="grey--text text-caption chip-extra"> +{{ filter.type.length - 1 }} </span>
          </template>
        </v-select>
      </div>
      <div class="mb-3">
        <date-picker
          v-model="filter.sentAt"
          format="YYYY-MM-DD"
          range
          value-type="format"
          :disabled-date="utilityMixin_disabledDateMaxNow"
          :clearable="false"
          style="width: 100%"
        >
          <i slot="icon-calendar"></i>
          <template #input>
            <v-text-field
              outlined
              dense
              single-line
              :placeholder="$t('content.select_date')"
              readonly
              v-model="filter.sentAt"
              :hide-details="true"
              class="rounded-lg"
              background-color="#fff"
              clearable
            >
              <svg-icon icon="icon-calendar" slot="append" class="mt-1" />
            </v-text-field>
          </template>
        </date-picker>
      </div>
    </section>

    <!-- Deliverable - Table -->
    <v-data-table
      dense
      hide-default-footer
      :loading="loading"
      :headers="table.headers"
      :items="deliverables.results"
      :server-items-length="deliverables.overall_total"
      class="custom-table"
    >
      <template #[`header.file_name`]="{ header }">
        {{ header.text }}
        <div class="mt-2">
          <v-text-field
            outlined
            dense
            v-model="filter.deliverableName"
            :placeholder="`${$t('content.search')}...`"
            single-line
            class="rounded-lg"
            :hide-details="true"
            clearable
            background-color="#fff"
          />
        </div>
      </template>
      <template #[`header.idfl_numbers`]="{ header }">
        {{ header.text }}
        <div class="mt-2">
          <v-text-field
            outlined
            dense
            v-model="filter.idflNumber"
            :placeholder="`${$t('content.search_idfl')}...`"
            single-line
            class="rounded-lg"
            :hide-details="true"
            clearable
            background-color="#fff"
          />
        </div>
      </template>
      <template #[`header.type`]="{header}">
        {{ header.text }}
        <div class="mt-2" style="max-width:250px;">
          <v-select
            class="rounded-lg"
            background-color="#fff"
            outlined
            dense
            v-model="filter.type"
            single-line
            multiple
            hide-details
            :items="types"
            item-text="name"
            item-value="id"
            :placeholder="$t('content.select_type')"
          >
            <template #selection="{ item, index }">
              <v-chip
                v-if="index === 0"
                class="chip-selector ma-2"
                :class="{ 'chip-selector-single': filter.type.length === 1 }"
                small
                color="primary"
              >
                {{ item.name }}
              </v-chip>
              <span v-if="index === 1" class="grey--text text-caption chip-extra"> +{{ filter.type.length - 1 }} </span>
            </template>
          </v-select>
        </div>
      </template>
      <template #[`header.sent_at`]="{ header }">
        {{ header.text }}
        <div class="mt-2">
          <date-picker
            v-model="filter.sentAt"
            format="YYYY-MM-DD"
            range
            value-type="format"
            :disabled-date="utilityMixin_disabledDateMaxNow"
            :clearable="false"
          >
            <i slot="icon-calendar"></i>
            <template #input>
              <v-text-field
                outlined
                dense
                single-line
                :placeholder="$t('content.select_date')"
                readonly
                v-model="filter.sentAt"
                :hide-details="true"
                class="rounded-lg"
                background-color="#fff"
                clearable
              >
                <svg-icon icon="icon-calendar" slot="append" class="mt-1" />
              </v-text-field>
            </template>
          </date-picker>
        </div>
      </template>

      <template #[`item.file_name`]="{ item }">
        {{ changeName(item.file_name) }}
      </template>
      <template #[`item.idfl_numbers`]="{ value }">
        <v-expansion-panels accordion flat v-if="value.length > 1" class="expansion-title">
          <v-expansion-panel class="expansion-panel">
            <v-expansion-panel-header class="px-0">
              {{ value[0] }}
              <template #actions>
                <v-icon color="blue lighten-1">
                  mdi-menu-down
                </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content v-for="(item, index) in value.slice(1)" :key="index">
              {{ item }}
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-expansion-panels accordion flat v-else>
          <v-expansion-panel class="expansion-panel">
            <v-expansion-panel-header hide-actions class="px-0">
              {{ value[0] }}
            </v-expansion-panel-header>
          </v-expansion-panel>
        </v-expansion-panels>
      </template>
      <template #[`item.sent_at`]="{ item }">
        {{ utilityMixin_formatDateTime(item.sent_at) }}
      </template>
      <template #[`item.actions`]="{ item }">
        <BaseActionButton :tooltipsText="$t('content.view_document')" @click="onViewDocument(item)">
          <SvgIcon icon="icon-eye" width="20px" height="20px" />
        </BaseActionButton>
        <BaseActionButton
          :disabled="loadingShare && selectedItem == item"
          :tooltipsText="$t('content.share')"
          :selectedItem="item"
          @click="onViewUserShared(item)"
        >
          <v-progress-circular v-if="loadingShare && selectedItem == item" :size="22" color="primary-light" indeterminate />
          <SvgIcon v-else icon="icon-share" width="20px" height="20px" />
        </BaseActionButton>
        <BaseActionButton :tooltipsText="$t('content.download')" @click="onDownload(item)">
          <SvgIcon icon="icon-download" width="20px" height="20px" />
        </BaseActionButton>
      </template>

      <template #no-data>
        <BaseNoData :message="$t('content.deliverable_no_data')" />
      </template>
      <template #no-results>
        <BaseDataNotFound :message="$t('content.deliverable_notfound_message')" />
      </template>
      <template #footer>
        <BaseTableFooter
          :data="deliverables"
          :filter="filter"
          :params="table.params"
          customLength
          :customLengthFunc="onCustomLengthTable"
          @change="onChangeTable"
          contentText="content.perType"
        />
      </template>
    </v-data-table>

    <DeliverableDialogShare :deliverable="selectedItem" :dialog="dialogUserShared" @close="onCloseDialog" />

    <DocumentViewer
      :dialog="dialogDocument"
      :item.sync="selectedItem"
      :title="selectedItem.file_name ? changeName(selectedItem.file_name) : ''"
      @close="onCloseDialog"
    />

    <BaseAlert ref="alert" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import debounce from 'lodash/debounce';

import { utilityMixin, downloadMixin } from '@/mixins';

import DatePicker from 'vue2-datepicker';
import DeliverableDialogShare from '@/components/deliverable/DelivableDialogShare';
import DocumentViewer from '@/components/dialog/DocumentViewer';

export default {
  name: 'DeliverableCenter',
  mixins: [utilityMixin, downloadMixin],
  components: {
    DatePicker,
    DeliverableDialogShare,
    DocumentViewer,
  },
  data() {
    return {
      filter: {
        deliverableName: null,
        idflNumber: [],
        sentAt: null,
        type: [],
      },
      table: {
        headers: [
          { text: this.$t('content.deliverable_name'), value: 'file_name', sortable: false, width: 250 },
          { text: this.$t('content.idflNumber'), value: 'idfl_numbers', sortable: false, width: 205 },
          { text: this.$t('content.date'), value: 'sent_at', sortable: false, width: 200 },
          { text: this.$t('content.type'), value: 'type', sortable: false, width: 240 },
          { text: this.$t('content.actions'), value: 'actions', sortable: false, width: 160 },
        ],
        params: { limit: 10 },
      },
      dialogDocument: false,
      dialogUserShared: false,
      loading: false,
      loadingShare: false,
      selectedItem: {},
      shareType: [
        { title: `${this.$t('content.share')} PDF`, action: 'pdf' },
        { title: `${this.$t('content.share')} Link`, action: 'link' },
        { title: `${this.$t('content.share')} All`, action: 'all' },
      ],
    };
  },
  computed: {
    ...mapGetters({
      types: 'DeliverableCenter/types',
      deliverables: 'DeliverableCenter/deliverables',
      doc: 'File/doc',
    }),
    sentAtValue() {
      return this.utilityMixin_splitDate(this.filter.sentAt, 'startDate', 'endDate');
    },
    typeValue() {
      const values = this.filter.type;
      return { type_id: values.length > 0 ? values.toString() : null };
    },
  },
  watch: {
    filter: {
      deep: true,
      handler: debounce(function(filter) {
        this.onChangeTable({
          ...filter,
          ...(!this.initial ? { offset: 0, page: 1 } : null),
        });
      }, 300),
    },
    '$route.query': {
      immediate: true,
      handler(qry) {
        if (qry.type_id && qry.id) {
          const params = {
            type: [+this.$route.query.type_id],
            id: +this.$route.query.id,
          };
          this.filter = { ...this.filter, ...params };
          this.fetchData().then(data => this.onViewDocument(data.results[0]));
        }
      },
    },
  },
  methods: {
    fetchData() {
      return new Promise((resolve, reject) => {
        this.loading = true;
        this.$store.commit('DeliverableCenter/RESET_DELIVERABLES');

        const params = {
          ...this.table.params,
          ...this.utilityMixin_mappingValue(this.filter, {
            idflNumber: 'idfl_number',
            deliverableName: 'deliverable_name',
          }),
          ...this.sentAtValue,
          ...this.typeValue,
        };

        this.utilityMixin_deleteKeys(params, ['sentAt', 'type', 'idflNumber', 'deliverableName']);

        this.$store
          .dispatch('DeliverableCenter/fetchAll', params)
          .then(data => resolve(data))
          .catch(err => {
            this.$toast.error(err.message);
            reject();
          })
          .finally(() => {
            this.loading = false;
            this.initial = false;
          });
      });
    },
    fetchTypes() {
      this.$store.dispatch('DeliverableCenter/fetchTypes');
    },
    onChangeTable(params = {}) {
      this.table.params = { ...this.table.params, ...params };
      this.fetchData();
    },
    changeName(input) {
      return input
        .replaceAll('_', ' ')
        .split('.')
        .slice(0, -1)
        .join('.');
    },
    onCloseDialog() {
      this.dialogDocument = false;
      this.dialogUserShared = false;
      this.selectedItem = {};
    },
    onViewDocument(item) {
      this.selectedItem = item;
      this.dialogDocument = true;
    },
    onViewUserShared(item) {
      this.selectedItem = item;
      this.dialogUserShared = true;
    },
    onDownload(item) {
      const name = item.file_name;
      this.$store
        .dispatch('File/getData', {
          data: { name, url: item.file_url },
          type: 'doc',
        })
        .then(blob => this.downloadMixin_download(blob, { name, type: 'application/pdf' }))
        .finally(() => (this.loading = false));
    },
    onCustomLengthTable({ perPage }) {
      if (this.filter.type.length > 0) {
        return Math.ceil(this.deliverables.overall_total / (perPage * this.filter.type.length));
      } else if (this.types.length > 0) {
        return Math.ceil(this.deliverables.overall_total / (perPage * this.types.length));
      } else {
        return 0;
      }
    },
  },
  created() {
    this.fetchTypes();
    this.fetchData();
  },
  destroyed() {
    this.$store.commit('DeliverableCenter/RESET_DELIVERABLES');
  },
};
</script>
